<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <h3
        color="primary"
        align="center"
        justify="space-around"
        v-if="search.phone == 1"
      >
        Se encontró un broker externo registrado..
      </h3>
      <!--Campo de nombre -->
      <v-text-field
        class="pt-6"
        :disabled="disabled.form"
        dense
        autocomplete="off"
        id="txtLeadName"
        label="Nombre *"
        hint="Campo obligatorio *"
        persistent-hint
        :counter="35"
        v-model="exBr.name"
      />
      <!--Campo de E-Mail -->
      <v-text-field
        class="pt-6"
        ref="entry"
        :disabled="!disabled.email"
        :rules="emailRules"
        dense
        label="E-mail (Correo electrónico)"
        hint="Campo obligatorio *"
        persistent-hint
        v-model="exBr.email"
      >
        <template v-slot:append>
          <v-progress-circular
            v-if="loading.email"
            size="24"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <div v-else>
            <v-icon
              style="vertical-align: middle"
              color="#F1C40F"
              v-if="search.email == 1"
            >
              mdi-alert-circle-outline
            </v-icon>
            <v-icon
              style="vertical-align: middle"
              color="green"
              v-if="search.email == 2"
            >
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </div>
        </template>
      </v-text-field>
      <!--Campo de Telefono -->
      <div class="pt-6">
        <country-phone-entry
          :disabled="!disabled.phone"
          :phone="exBr.phone"
          :codePhone="exBr.countrySelectedCode"
          hintText="Campo obligatorio *"
          @setPhone="value => (exBr.phone = value)"
          @setCodePhone="value => (exBr.countrySelectedCode = value)"
        />
      </div>
      <!--Campo de Inmobiliaria -->
      <v-text-field
        class="pt-6"
        :disabled="disabled.form"
        dense
        label="Inmobiliaria"
        persistent-hint
        v-model="exBr.real_estate_name"
      />
      <div class="text-center">
        <v-btn
          class="ma-2"
          :loading="btnUpdate.loading"
          :disabled="btnUpdate.validate"
          color="primary"
          @click="actionUpdate(exBr)"
        >
          Actualizar
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>
<script>
import countries from "../../helpers/countries";
import { mapActions } from "vuex";
/* ..IDEAS A TOMAR EN CUENTA.. 
  1.Guardar los datos iniciales..
  2.Si se cambian los datos, ahora si buscar si se ha repetido...
  3.Poder identificar lada correctamente..
  4.Fusionar lada de us y canada para evitar errores...
  5.Eliminar espacios al guardar los datos del telefono y correo..
  6.Enfasis en buscar numeeros repetidos de correo y telefono... pero tambien hay que tener una copia de los datos originales..
*/
import { searchByRepeated } from "@/api/external-broker-api.js";
export default {
  name: "ExternalBrokerEdit",
  components: {
    CountryPhoneEntry: () =>
      import("@/components/lead/components/CountryPhoneEntry.vue")
  },
  props: {
    /* Vamos a indicar donde se va a usar el componente...
    Se puede utilizar para crear un nuevo broker externo y hay que validarlo...
    Tambien se puede utilizar para validar un lead con broker externo.. */
    typeComponent: {
      String: String,
      default: "ExternalBroker"
    },
    getItem: {
      Object: Object,
      default: null
    }
  },
  data() {
    return {
      btnUpdate: {
        validate: false,
        loading: false
      },
      //Validation
      validationScreen: 0,
      emailRules: [
        v => /.+@.+/.test(v) || v.length == 0 || "E-mail debe ser valido"
      ],
      valid: true,
      validSearchEmail: false,
      exBr: {
        _id: "",
        name: "",
        email: "",
        real_estate_name: "",
        //Datos de telefono..
        phone: "",
        countrySelectedCode: "+52"
      },
      exBrBackup: {},
      phone: "",
      countrySelectedCode: "+52",
      tempPhone: "",
      //Loading
      loading: {
        next: false,
        email: false
      },
      disabled: {
        next: false,
        form: false,
        phone: true,
        email: true
      },
      //search.phone: 0,
      search: {
        phone: 0,
        /* 
        0: No mostrar icono.
        1: Alerta No Puede Avanzar.
        2: Puede Avanzar. 
        */
        email: 0
      },
      timer: {
        email: null
      },
      tmpEmail: ""
    };
  },
  created() {
    this.init();
  },
  computed: {
    disabledNext() {
      if (this.phone.length !== 0) return true;
      return false;
    },
    joinData() {
      return this.exBr.name + this.exBr.email + this.exBr.real_estate_name;
    },
    validEmail() {
      if (this.valid && this.exBr.email.length > 3) return true;
      return false;
    },
    email() {
      return this.exBr.email;
    }
  },
  watch: {
    getItem() {
      this.init();
    },
    //Veficar que los whatch esten funcionanando bien...
    email: function(e) {
      if (this.search.phone == 1) return 0;
      if (this.tmpEmail === e) return (this.search.email = 2);
      setTimeout(() => {
        this.verificationFinal();
        this.validateEmailForSearch(e);
      }, 100);
    },
    joinData: function() {
      this.verificationFinal();
    },
    validationScreen: function(e) {
      this.$emit("setCurrentScreen", e);
    }
  },
  methods: {
    ...mapActions({
      updateBrex: "brex/updateBrex"
    }),
    init() {
      const oPhone = this.searchDialCode(this.getItem.phone);
      this.exBr._id = this.getItem._id;
      this.exBr.name = this.getItem.name;
      this.exBr.real_estate_name = this.getItem.real_estate_name;
      this.exBr.email = this.getItem.email;
      this.tmpEmail = this.getItem.email;
      this.exBr.phone = oPhone.phone;
      this.exBr.countrySelectedCode = oPhone.countrySelectedCode;
    },
    //Search Email...
    validateEmailForSearch() {
      this.validSearchEmail = false;
      this.search.email = 0;
      this.loading.email = false;
      this.validSearchEmail = false;
      this.verificationFinal();
      //Dentro del change...
      clearTimeout(this.timer.email);
      this.validSearchEmail = false;
      this.search.email = 0;
      this.loading.email = false;
      if (!this.validEmail) return 0;
      //Si cumple la validación puede continuar..
      if (this.validEmail) {
        this.loading.email = true;
        this.timer.email = setTimeout(() => {
          this.searchEmail();
        }, 1000);
      }
    },
    searchEmail() {
      ///Busqueda de email.--------------------------------------------------
      const data = {
        email: this.exBr.email.replace(/ /g, "")
      };
      searchByRepeated(data).then(response => {
        this.loading.email = false;
        if (response.data.status == 1) {
          this.search.email = 1;
          this.validSearchEmail = false;
        }
        if (response.data.status == 0) {
          this.search.email = 2;
          this.validSearchEmail = true;
        }

        setTimeout(() => {
          this.verificationFinal();
        }, 100);
      });
    },
    // Boton para buscar.----------------------------------------------------
    nextBtnSearch() {
      this.loading.next = true;
      const data = {
        phone: (this.countrySelectedCode + this.phone).replace(/ /g, "")
      };
      //Utlizar el try cach
      searchByRepeated(data).then(response => {
        if (!response && !response.data && !response.data.status) {
          this.loading.next = false;
          return 0;
        }
        //Falta el try
        this.assignPhone();
        this.loading.next = false;
        if (response.data.status == 0) this.validateCreateExternalBroker();
        if (response.data.status == 1) this.externalBrokerExists(response.data);
        setTimeout(() => {
          this.verificationFinal();
        }, 100);
      });
    },
    //Si no se encuentra el broker externo  ------> 0
    validateCreateExternalBroker() {
      setTimeout(() => this.$refs.form.validate(), 100);
      this.search.phone = 0;
      this.validationScreen = 1;
      this.disabled.form = false;
      //Datos asignados en el formulario..
      this.exBr.email = this.exBr.email;
      if (this.tempPhone !== this.exBr.countrySelectedCode + this.exBr.phone) {
        this.exBr.name = "";
        this.exBr.email = "";
        this.exBr.real_estate_name = "";
      }
    },
    //Si se encuentra el broker externo  ---------> 1
    externalBrokerExists(data) {
      this.search.phone = 1;
      this.validationScreen = 1;
      this.disabled.form = true;
      //Datos asignados en el formulario....
      this.exBr.name = data.name;
      this.exBr.email = data.email;
      this.exBr.real_estate_name = data.real_estate_name;
    },
    assignPhone() {
      this.exBr.phone = this.phone;
      this.exBr.countrySelectedCode = this.countrySelectedCode;
    },
    back() {
      this.tempPhone = this.exBr.countrySelectedCode + this.exBr.phone;
      this.validationScreen = 0;
      this.$emit("setValidate", false);
      this.$emit("setData", {});
    },
    //Validations
    validationFullform() {
      if (this.exBr.name.length == 0) return false;
      if (this.exBr.email.length == 0) return false;
      if (this.exBr.phone.length == 0) return false;
      if (this.exBr.countrySelectedCode.length == 0) return false;
      return true;
    },
    validationJoin() {
      if (this.validEmail && this.validationFullform()) return true;
      return false;
    },
    //Utils
    verificationFinal() {
      if (!this.validSearchEmail && this.search.phone == 0) {
        this.$emit("setValidate", false);
        this.$emit("setData", {});
        return 0;
      }
      if (!this.validationJoin()) {
        this.$emit("setValidate", false);
        this.$emit("setData", {});
        return 0;
      }

      if (this.search.phone == 1 && this.typeComponent === "Lead") {
        this.$emit("setValidate", true);
        this.$emit("setData", this.exBr);
        return 0;
      }
      if (this.search.phone == 0) {
        this.$emit("setValidate", true);
        this.$emit("setData", this.exBr);
        return 0;
      }
      this.$emit("setValidate", false);
      this.$emit("setData", {});
    },
    searchDialCode(phoneJoin) {
      let phone = phoneJoin;
      let countrySelectedCode = null;
      countries.forEach(country => {
        const result = phoneJoin.indexOf(country.dialCode);
        if (result !== -1) {
          phone = phoneJoin.replace(country.dialCode, "");
          countrySelectedCode = country.dialCode;
        }
      });
      return {
        phone: phone,
        countrySelectedCode: countrySelectedCode
      };
    },
    async actionUpdate(item) {
      const prep = this.reorganize(item);
      let sucessfullly = false;
      this.$snotify.async(
        "Actualizar Broker Externo",
        "Actualizando...",
        () =>
          new Promise(async (resolve, reject) => {
            try {
              sucessfullly = await this.updateBrex(prep);
              resolve({
                title: "Actualizado",
                body: "Información de broker Externo actualizado",
                config: {
                  closeOnClick: true,
                  titleMaxLength: 30,
                  timeout: 4000
                }
              });
            } catch (error) {
              reject({
                title: "Error!!!",
                body: "Ocurrio un problema al actualizar los datos.",
                config: {
                  closeOnClick: true
                }
              });
            }
          })
      );
      this.$emit("closeModal", sucessfullly);
    },
    reorganize(item) {
      return {
        external_broker_id: item._id,
        email: item.email,
        name: item.name,
        phone: `${item.countrySelectedCode}${item.phone}`,
        real_estate_name: item.real_estate_name
      };
    }
  }
};
</script>
