const countries = [
  { alpha2: "AF", dialCode: "+93", nameLatinAmerica: "Afganistán" },
  { alpha2: "AX", dialCode: "+358", nameLatinAmerica: "Islas Åland" },

  { alpha2: "AL", dialCode: "+355", nameLatinAmerica: "Albania" },

  { alpha2: "DZ", dialCode: "+213", nameLatinAmerica: "Argelia" },

  { alpha2: "AS", dialCode: "+1684", nameLatinAmerica: "Samoa Americana" },

  { alpha2: "AD", dialCode: "+376", nameLatinAmerica: "Andorra" },

  { alpha2: "AO", dialCode: "+244", nameLatinAmerica: "Angola" },

  { alpha2: "AI", dialCode: "+1264", nameLatinAmerica: "Anguila" },

  { alpha2: "AQ", dialCode: "+672", nameLatinAmerica: "Antártida" },

  {
    alpha2: "AG",
    dialCode: "+1268",
    nameLatinAmerica: "Antigua y Barbuda"
  },

  { alpha2: "AR", dialCode: "+54", nameLatinAmerica: "Argentina" },

  { alpha2: "AM", dialCode: "+374", nameLatinAmerica: "Armenia" },

  { alpha2: "AW", dialCode: "+297", nameLatinAmerica: "Aruba" },

  { alpha2: "AU", dialCode: "+61", nameLatinAmerica: "Australia" },

  { alpha2: "AT", dialCode: "+43", nameLatinAmerica: "Austria" },

  { alpha2: "AZ", dialCode: "+994", nameLatinAmerica: "Azerbaiyán" },

  { alpha2: "BS", dialCode: "+1242", nameLatinAmerica: "Bahamas" },

  { alpha2: "BH", dialCode: "+973", nameLatinAmerica: "Baréin" },

  { alpha2: "BD", dialCode: "+880", nameLatinAmerica: "Bangladés" },

  { alpha2: "BB", dialCode: "+1246", nameLatinAmerica: "Barbados" },

  { alpha2: "BY", dialCode: "+375", nameLatinAmerica: "Bielorrusia" },

  { alpha2: "BE", dialCode: "+32", nameLatinAmerica: "Bélgica" },

  { alpha2: "BZ", dialCode: "+501", nameLatinAmerica: "Belice" },

  { alpha2: "BJ", dialCode: "+229", nameLatinAmerica: "Benín" },

  { alpha2: "BM", dialCode: "+1441", nameLatinAmerica: "Bermudas" },

  { alpha2: "BT", dialCode: "+975", nameLatinAmerica: "Bután" },

  { alpha2: "BO", dialCode: "+591", nameLatinAmerica: "Bolivia" },

  {
    alpha2: "BA",
    dialCode: "+387",
    nameLatinAmerica: "Bosnia y Herzegovina"
  },

  { alpha2: "BW", dialCode: "+267", nameLatinAmerica: "Botsuana" },

  { alpha2: "BV", dialCode: "+47", nameLatinAmerica: "Isla Bouvet" },

  { alpha2: "BR", dialCode: "+55", nameLatinAmerica: "Brasil" },

  {
    alpha2: "IO",
    dialCode: "+246",
    nameLatinAmerica: "Territorio Británico del Océano Índico"
  },

  { alpha2: "BN", dialCode: "+673", nameLatinAmerica: "Brunéi" },

  { alpha2: "BG", dialCode: "+359", nameLatinAmerica: "Bulgaria" },

  { alpha2: "BF", dialCode: "+226", nameLatinAmerica: "Burkina Faso" },

  { alpha2: "BI", dialCode: "+257", nameLatinAmerica: "Burundi" },

  { alpha2: "CV", dialCode: "+238", nameLatinAmerica: "Cabo Verde" },

  { alpha2: "KH", dialCode: "+855", nameLatinAmerica: "Camboya" },

  { alpha2: "CM", dialCode: "+237", nameLatinAmerica: "Camerún" },

  { alpha2: "CA", dialCode: "+1", nameLatinAmerica: "Canadá" },

  { alpha2: "KY", dialCode: "+1345", nameLatinAmerica: "Islas Caimán" },

  {
    alpha2: "CF",
    dialCode: "+236",
    nameLatinAmerica: "República Centroafricana"
  },

  { alpha2: "TD", dialCode: "+235", nameLatinAmerica: "Chad" },

  { alpha2: "CL", dialCode: "+56", nameLatinAmerica: "Chile" },

  { alpha2: "CN", dialCode: "+86", nameLatinAmerica: "China" },

  { alpha2: "CX", dialCode: "+61", nameLatinAmerica: "Isla de Navidad" },

  { alpha2: "CC", dialCode: "+61", nameLatinAmerica: "Islas Cocos" },

  { alpha2: "CO", dialCode: "+57", nameLatinAmerica: "Colombia" },

  { alpha2: "KM", dialCode: "+269", nameLatinAmerica: "Comoras" },

  { alpha2: "CG", dialCode: "+242", nameLatinAmerica: "Congo" },

  {
    alpha2: "CD",
    dialCode: "+243",
    nameLatinAmerica: "República Democrática del Congo"
  },

  { alpha2: "CK", dialCode: "+682", nameLatinAmerica: "Islas Cook" },

  { alpha2: "CR", dialCode: "+506", nameLatinAmerica: "Costa Rica" },

  { alpha2: "CI", dialCode: "+225", nameLatinAmerica: "Côte d'Ivoire" },

  { alpha2: "HR", dialCode: "+385", nameLatinAmerica: "Croacia" },

  { alpha2: "CU", dialCode: "+53", nameLatinAmerica: "Cuba" },

  { alpha2: "CY", dialCode: "+357", nameLatinAmerica: "Chipre" },

  { alpha2: "CZ", dialCode: "+420", nameLatinAmerica: "Chequia" },

  { alpha2: "DK", dialCode: "+45", nameLatinAmerica: "Dinamarca" },

  { alpha2: "DJ", dialCode: "+253", nameLatinAmerica: "Yibuti" },

  { alpha2: "DM", dialCode: "+1767", nameLatinAmerica: "Dominica" },

  {
    alpha2: "DO",
    dialCode: "+1",
    nameLatinAmerica: "República Dominicana"
  },

  { alpha2: "EC", dialCode: "+593", nameLatinAmerica: "Ecuador" },

  { alpha2: "EG", dialCode: "+20", nameLatinAmerica: "Egipt" },

  { alpha2: "SV", dialCode: "+503", nameLatinAmerica: "El Salvador" },

  { alpha2: "GQ", dialCode: "+240", nameLatinAmerica: "Guinea Ecuatorial" },

  { alpha2: "ER", dialCode: "+291", nameLatinAmerica: "Eritrea" },

  { alpha2: "EE", dialCode: "+372", nameLatinAmerica: "Estonia" },

  { alpha2: "ET", dialCode: "+251", nameLatinAmerica: "Etiopía" },

  { alpha2: "FK", dialCode: "+500", nameLatinAmerica: "Islas Malvinas" },

  { alpha2: "FO", dialCode: "+298", nameLatinAmerica: "Islas Feroe" },

  { alpha2: "FJ", dialCode: "+679", nameLatinAmerica: "Fiyi" },

  { alpha2: "FI", dialCode: "+358", nameLatinAmerica: "Finlandia" },

  { alpha2: "FR", dialCode: "+33", nameLatinAmerica: "Francia" },

  { alpha2: "GF", dialCode: "+594", nameLatinAmerica: "Guayana Francesa" },

  {
    alpha2: "PF",
    dialCode: "+689",
    nameLatinAmerica: "Polinesia Francesa"
  },

  {
    alpha2: "TF",
    dialCode: "+262",
    nameLatinAmerica: "Territorios Australes Franceses"
  },

  { alpha2: "GA", dialCode: "+241", nameLatinAmerica: "Gabón" },

  { alpha2: "GM", dialCode: "+220", nameLatinAmerica: "Gambia" },

  { alpha2: "GE", dialCode: "+995", nameLatinAmerica: "Georgia" },

  { alpha2: "DE", dialCode: "+49", nameLatinAmerica: "Alemania" },

  { alpha2: "GH", dialCode: "+233", nameLatinAmerica: "Ghana" },

  { alpha2: "GI", dialCode: "+350", nameLatinAmerica: "Gibraltar" },

  { alpha2: "GR", dialCode: "+30", nameLatinAmerica: "Grecia" },

  { alpha2: "GL", dialCode: "+299", nameLatinAmerica: "Groenlandia" },

  { alpha2: "GD", dialCode: "+1473", nameLatinAmerica: "Granada" },

  { alpha2: "GP", dialCode: "+590", nameLatinAmerica: "Guadalupe" },

  { alpha2: "GU", dialCode: "+1671", nameLatinAmerica: "Guam" },

  { alpha2: "GT", dialCode: "+502", nameLatinAmerica: "Guatemala" },

  { alpha2: "GG", dialCode: "+44", nameLatinAmerica: "Guernsey" },

  { alpha2: "GN", dialCode: "+224", nameLatinAmerica: "Guinea" },

  { alpha2: "GW", dialCode: "+245", nameLatinAmerica: "Guinea-Bisáu" },

  { alpha2: "GY", dialCode: "+592", nameLatinAmerica: "Guyana" },

  { alpha2: "HT", dialCode: "+509", nameLatinAmerica: "Haití" },

  {
    alpha2: "HM",
    dialCode: "+0",
    nameLatinAmerica: "Islas Heard y McDonald"
  },

  {
    alpha2: "VA",
    dialCode: "+379",
    nameLatinAmerica: "Ciudad del Vaticano"
  },

  { alpha2: "HN", dialCode: "+504", nameLatinAmerica: "Honduras" },

  {
    alpha2: "HK",
    dialCode: "+852",
    nameLatinAmerica: "RAE de Hong Kong (China)"
  },

  { alpha2: "HU", dialCode: "+36", nameLatinAmerica: "Hungría" },

  { alpha2: "IS", dialCode: "+354", nameLatinAmerica: "Islandia" },
  { alpha2: "IN", dialCode: "+91", nameLatinAmerica: "India" },

  { alpha2: "ID", dialCode: "+62", nameLatinAmerica: "Indonesia" },

  { alpha2: "IR", dialCode: "+98", nameLatinAmerica: "Irán" },

  { alpha2: "IQ", dialCode: "+964", nameLatinAmerica: "Irak" },

  { alpha2: "IE", dialCode: "+353", nameLatinAmerica: "Irlanda" },

  { alpha2: "IM", dialCode: "+44", nameLatinAmerica: "Isla de Man" },

  { alpha2: "IL", dialCode: "+972", nameLatinAmerica: "Israel" },

  { alpha2: "IT", dialCode: "+39", nameLatinAmerica: "Italia" },

  { alpha2: "JM", dialCode: "+1876", nameLatinAmerica: "Jamaica" },

  { alpha2: "JP", dialCode: "+81", nameLatinAmerica: "Japón" },

  { alpha2: "JE", dialCode: "+44", nameLatinAmerica: "Jersey" },

  { alpha2: "JO", dialCode: "+962", nameLatinAmerica: "Jordania" },

  { alpha2: "KZ", dialCode: "+7", nameLatinAmerica: "Kazajistán" },

  { alpha2: "KE", dialCode: "+254", nameLatinAmerica: "Kenia" },

  { alpha2: "KI", dialCode: "+686", nameLatinAmerica: "Kiribati" },

  { alpha2: "KP", dialCode: "+850", nameLatinAmerica: "Corea del Norte" },

  { alpha2: "KR", dialCode: "+82", nameLatinAmerica: "Corea del Sur" },

  { alpha2: "KW", dialCode: "+965", nameLatinAmerica: "Kuwait" },

  { alpha2: "KG", dialCode: "+996", nameLatinAmerica: "Kirguistán" },

  { alpha2: "LA", dialCode: "+856", nameLatinAmerica: "Laos" },

  { alpha2: "LV", dialCode: "+371", nameLatinAmerica: "Letonia" },

  { alpha2: "LB", dialCode: "+961", nameLatinAmerica: "Líbano" },

  { alpha2: "LS", dialCode: "+266", nameLatinAmerica: "Lesoto" },

  { alpha2: "LR", dialCode: "+231", nameLatinAmerica: "Liberia" },

  { alpha2: "LY", dialCode: "+218", nameLatinAmerica: "Libia" },

  { alpha2: "LI", dialCode: "+423", nameLatinAmerica: "Liechtenstein" },

  { alpha2: "LT", dialCode: "+370", nameLatinAmerica: "Lituania" },

  { alpha2: "LU", dialCode: "+352", nameLatinAmerica: "Luxemburgo" },

  {
    alpha2: "MO",
    dialCode: "+853",
    nameLatinAmerica: "RAE de Macao (China)"
  },

  {
    alpha2: "MK",
    dialCode: "+389",
    nameLatinAmerica: "Macedonia del Norte"
  },

  { alpha2: "MG", dialCode: "+261", nameLatinAmerica: "Madagascar" },

  { alpha2: "MW", dialCode: "+265", nameLatinAmerica: "Malaui" },

  { alpha2: "MY", dialCode: "+60", nameLatinAmerica: "Malasia" },

  { alpha2: "MV", dialCode: "+960", nameLatinAmerica: "Maldivas" },

  { alpha2: "ML", dialCode: "+223", nameLatinAmerica: "Mali" },

  { alpha2: "MT", dialCode: "+356", nameLatinAmerica: "Malta" },

  { alpha2: "MH", dialCode: "+692", nameLatinAmerica: "Islas Marshall" },

  { alpha2: "MQ", dialCode: "+596", nameLatinAmerica: "Martinica" },

  { alpha2: "MR", dialCode: "+222", nameLatinAmerica: "Mauritania" },

  { alpha2: "MU", dialCode: "+230", nameLatinAmerica: "Mauricio" },

  { alpha2: "YT", dialCode: "+262", nameLatinAmerica: "Mayotte" },

  { alpha2: "MX", dialCode: "+52", nameLatinAmerica: "México" },

  { alpha2: "FM", dialCode: "+691", nameLatinAmerica: "Micronesia" },

  { alpha2: "MD", dialCode: "+373", nameLatinAmerica: "Moldavia" },

  { alpha2: "MC", dialCode: "+377", nameLatinAmerica: "Mónaco" },

  { alpha2: "MN", dialCode: "+976", nameLatinAmerica: "Mongolia" },

  { alpha2: "ME", dialCode: "+382", nameLatinAmerica: "Montenegro" },

  { alpha2: "MS", dialCode: "+1664", nameLatinAmerica: "Montserrat" },

  { alpha2: "MA", dialCode: "+212", nameLatinAmerica: "Marruecos" },

  { alpha2: "MZ", dialCode: "+258", nameLatinAmerica: "Mozambique" },

  { alpha2: "MM", dialCode: "+95", nameLatinAmerica: "Myanmar (Birmania)" },

  { alpha2: "NA", dialCode: "+264", nameLatinAmerica: "Namibia" },

  { alpha2: "NR", dialCode: "+674", nameLatinAmerica: "Nauru" },

  { alpha2: "NP", dialCode: "+977", nameLatinAmerica: "Nepal" },

  { alpha2: "NL", dialCode: "+31", nameLatinAmerica: "Países Bajos" },

  { alpha2: "NC", dialCode: "+687", nameLatinAmerica: "Nueva Caledonia" },

  { alpha2: "NZ", dialCode: "+64", nameLatinAmerica: "Nueva Zelanda" },

  { alpha2: "NI", dialCode: "+505", nameLatinAmerica: "Nicaragua" },

  { alpha2: "NE", dialCode: "+227", nameLatinAmerica: "Níger" },

  { alpha2: "NG", dialCode: "+234", nameLatinAmerica: "Nigeria" },

  { alpha2: "NU", dialCode: "+683", nameLatinAmerica: "Niue" },

  { alpha2: "NF", dialCode: "+672", nameLatinAmerica: "Isla Norfolk" },

  {
    alpha2: "MP",
    dialCode: "+1670",
    nameLatinAmerica: "Islas Marianas del Norte"
  },

  { alpha2: "NO", dialCode: "+47", nameLatinAmerica: "Noruega" },

  { alpha2: "OM", dialCode: "+968", nameLatinAmerica: "Omán" },

  { alpha2: "PK", dialCode: "+92", nameLatinAmerica: "Pakistán" },

  { alpha2: "PW", dialCode: "+680", nameLatinAmerica: "Palaos" },

  {
    alpha2: "PS",
    dialCode: "+970",
    nameLatinAmerica: "Territorios Palestinos"
  },

  { alpha2: "PA", dialCode: "+507", nameLatinAmerica: "Panamá" },

  {
    alpha2: "PG",
    dialCode: "+675",
    nameLatinAmerica: "Papúa Nueva Guinea"
  },

  { alpha2: "PY", dialCode: "+595", nameLatinAmerica: "Paraguay" },

  { alpha2: "PE", dialCode: "+51", nameLatinAmerica: "Perú" },

  { alpha2: "PH", dialCode: "+63", nameLatinAmerica: "Filipinas" },

  { alpha2: "PN", dialCode: "+64", nameLatinAmerica: "Islas Pitcairn" },

  { alpha2: "PL", dialCode: "+48", nameLatinAmerica: "Polonia" },

  { alpha2: "PT", dialCode: "+351", nameLatinAmerica: "Portugal" },

  { alpha2: "PR", dialCode: "+1939", nameLatinAmerica: "Puerto Rico" },

  { alpha2: "QA", dialCode: "+974", nameLatinAmerica: "Catar" },

  { alpha2: "RE", dialCode: "+262", nameLatinAmerica: "Reunión" },

  { alpha2: "RO", dialCode: "+40", nameLatinAmerica: "Rumanía" },

  { alpha2: "RU", dialCode: "+7", nameLatinAmerica: "Rusia" },

  { alpha2: "RW", dialCode: "+250", nameLatinAmerica: "Ruanda" },

  { alpha2: "BL", dialCode: "+590", nameLatinAmerica: "San Bartolomé" },

  { alpha2: "SH", dialCode: "+290", nameLatinAmerica: "Santa Elena" },

  {
    alpha2: "KN",
    dialCode: "+1869",
    nameLatinAmerica: "San Cristóbal y Nieves"
  },

  { alpha2: "LC", dialCode: "+1758", nameLatinAmerica: "Santa Lucía" },

  { alpha2: "MF", dialCode: "+590", nameLatinAmerica: "San Martín" },

  {
    alpha2: "PM",
    dialCode: "+508",
    nameLatinAmerica: "San Pedro y Miquelón"
  },

  {
    alpha2: "VC",
    dialCode: "+1784",
    nameLatinAmerica: "San Vicente y las Granadinas"
  },

  { alpha2: "WS", dialCode: "+685", nameLatinAmerica: "Samoa" },

  { alpha2: "SM", dialCode: "+378", nameLatinAmerica: "San Marino" },

  {
    alpha2: "ST",
    dialCode: "+239",
    nameLatinAmerica: "Santo Tomé y Príncipe"
  },

  { alpha2: "SA", dialCode: "+966", nameLatinAmerica: "Arabia Saudí" },

  { alpha2: "SN", dialCode: "+221", nameLatinAmerica: "Senegal" },

  { alpha2: "RS", dialCode: "+381", nameLatinAmerica: "Serbia" },

  { alpha2: "SC", dialCode: "+248", nameLatinAmerica: "Seychelles" },

  { alpha2: "SL", dialCode: "+232", nameLatinAmerica: "Sierra Leona" },

  { alpha2: "SG", dialCode: "+65", nameLatinAmerica: "Singapur" },

  { alpha2: "SK", dialCode: "+421", nameLatinAmerica: "Eslovaquia" },

  { alpha2: "SI", dialCode: "+386", nameLatinAmerica: "Eslovenia" },

  { alpha2: "SB", dialCode: "+677", nameLatinAmerica: "Islas Salomón" },

  { alpha2: "SO", dialCode: "+252", nameLatinAmerica: "Somalia" },

  { alpha2: "ZA", dialCode: "+27", nameLatinAmerica: "Sudáfrica" },

  {
    alpha2: "GS",
    dialCode: "+500",
    nameLatinAmerica: "Islas Georgia del Sur y Sandwich del Sur"
  },

  { alpha2: "SS", dialCode: "+211", nameLatinAmerica: "Sudán del Sur" },

  { alpha2: "ES", dialCode: "+34", nameLatinAmerica: "España" },

  { alpha2: "LK", dialCode: "+94", nameLatinAmerica: "Sri Lanka" },

  { alpha2: "SD", dialCode: "+249", nameLatinAmerica: "Sudán" },

  { alpha2: "SR", dialCode: "+597", nameLatinAmerica: "Surinam" },

  {
    alpha2: "SJ",
    dialCode: "+47",
    nameLatinAmerica: "Svalbard y Jan Mayen"
  },

  { alpha2: "SZ", dialCode: "+268", nameLatinAmerica: "Esuatini" },

  { alpha2: "SE", dialCode: "+46", nameLatinAmerica: "Suecia" },

  { alpha2: "CH", dialCode: "+41", nameLatinAmerica: "Suiza" },

  { alpha2: "SY", dialCode: "+963", nameLatinAmerica: "Siria" },

  { alpha2: "TW", dialCode: "+886", nameLatinAmerica: "Taiwán" },

  { alpha2: "TJ", dialCode: "+992", nameLatinAmerica: "Tayikistán" },

  { alpha2: "TZ", dialCode: "+255", nameLatinAmerica: "Tanzania" },

  { alpha2: "TH", dialCode: "+66", nameLatinAmerica: "Tailandia" },

  { alpha2: "TL", dialCode: "+670", nameLatinAmerica: "Timor-Leste" },

  { alpha2: "TG", dialCode: "+228", nameLatinAmerica: "Togo" },

  { alpha2: "TK", dialCode: "+690", nameLatinAmerica: "Tokelau" },

  { alpha2: "TO", dialCode: "+676", nameLatinAmerica: "Tonga" },

  {
    alpha2: "TT",
    dialCode: "+1868",
    nameLatinAmerica: "Trinidad y Tobago"
  },

  { alpha2: "TN", dialCode: "+216", nameLatinAmerica: "Túnez" },

  { alpha2: "TR", dialCode: "+90", nameLatinAmerica: "Turquía" },

  { alpha2: "TM", dialCode: "+993", nameLatinAmerica: "Turkmenistán" },

  {
    alpha2: "TC",
    dialCode: "+1649",
    nameLatinAmerica: "Islas Turcas y Caicos"
  },

  { alpha2: "TV", dialCode: "+688", nameLatinAmerica: "Tuvalu" },

  { alpha2: "UG", dialCode: "+256", nameLatinAmerica: "Uganda" },

  { alpha2: "UA", dialCode: "+380", nameLatinAmerica: "Ucrania" },

  {
    alpha2: "AE",
    dialCode: "+971",
    nameLatinAmerica: "Emiratos Árabes Unidos"
  },

  { alpha2: "GB", dialCode: "+44", nameLatinAmerica: "Reino Unido" },

  { alpha2: "US", dialCode: "+1", nameLatinAmerica: "Estados Unidos" },

  { alpha2: "UY", dialCode: "+598", nameLatinAmerica: "Uruguay" },

  { alpha2: "UZ", dialCode: "+998", nameLatinAmerica: "Uzbekistán" },

  { alpha2: "VU", dialCode: "+678", nameLatinAmerica: "Vanuatu" },

  { alpha2: "VE", dialCode: "+58", nameLatinAmerica: "Venezuela" },

  { alpha2: "VN", dialCode: "+84", nameLatinAmerica: "Vietnam" },

  {
    alpha2: "VG",
    dialCode: "+1284",
    nameLatinAmerica: "Islas Vírgenes Británicas"
  },

  {
    alpha2: "VI",
    dialCode: "+1340",
    nameLatinAmerica: "Islas Vírgenes de EE. UU."
  },

  { alpha2: "WF", dialCode: "+681", nameLatinAmerica: "Wallis y Futuna" },

  { alpha2: "YE", dialCode: "+967", nameLatinAmerica: "Yemen" },

  { alpha2: "ZM", dialCode: "+260", nameLatinAmerica: "Zambia" },

  { alpha2: "ZW", dialCode: "+263", nameLatinAmerica: "Zimbabue" }
];

export default countries;
